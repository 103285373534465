import { CanDeactivateGuard } from './can-deactivate.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, SharedPipesModule } from './shared';
import { RouteService } from './route.service';
import { PagerService } from './pager.service';
import { DatetimeHelperService } from './helpers/datetime-helper.service';
import { DialogService } from './dialog.service';
import { FormHelperService } from './helpers/form-helper.service';

@NgModule({
	imports: [
		NgbModule,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		SharedPipesModule,
		AppRoutingModule
	],
	declarations: [AppComponent],
	providers: [AuthGuard, RouteService, PagerService, DatetimeHelperService, CanDeactivateGuard, DialogService, FormHelperService],
	bootstrap: [AppComponent]
})
export class AppModule { }
