import { mergeMap, map, filter } from 'rxjs/operators';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	private navigationKeys = [
		'Backspace',
		'Delete',
		'Tab',
		'Escape',
		'Enter',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste'
	];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private title: Title
	) {}

	ngOnInit() {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map(route => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter(route => route.outlet === 'primary'),
				mergeMap(route => route.data)
			)
			.subscribe(event =>
				this.title.setTitle('Mission Control - ' + event['title'])
			);
	}

	@HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
		this.disableScroll(event);
	}

	@HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(
		event: any
	) {
		this.disableScroll(event);
	}

	@HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
		this.disableScroll(event);
	}

	disableScroll(event: any) {
		if (event.srcElement.type === 'number') event.preventDefault();
	}

	@HostListener('keydown', ['$event']) onKeyDown(event: any) {
		this.checkIfNumber(event);
	}

	checkIfNumber(event: any) {
		if (event.srcElement.type === 'number') {
			if (
				this.navigationKeys.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
				event.key === '.' || // Allow: '.'
				(event.key === 'a' && event.ctrlKey === true) || // Allow: Ctrl+A
				(event.key === 'c' && event.ctrlKey === true) || // Allow: Ctrl+C
				(event.key === 'v' && event.ctrlKey === true) || // Allow: Ctrl+V
				(event.key === 'x' && event.ctrlKey === true) || // Allow: Ctrl+X
				(event.key === 'a' && event.metaKey === true) || // Allow: Cmd+A (Mac)
				(event.key === 'c' && event.metaKey === true) || // Allow: Cmd+C (Mac)
				(event.key === 'v' && event.metaKey === true) || // Allow: Cmd+V (Mac)
				(event.key === 'x' && event.metaKey === true) // Allow: Cmd+X (Mac)
			) {
				// let it happen, don't do anything
				return;
			}
			// Ensure that it is a number and stop the keypress
			if (isNaN(+event.key)) {
				event.preventDefault();
			}
		}
	}
}
