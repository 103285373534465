<div class="row">
	<div class="col-xl-12">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<a href="Javascript:void(0)" [routerLink]="['/dashboard']">Dashboard</a>
			</li>
			<li class="breadcrumb-item" *ngFor="let nav of path">
				<a href="Javascript:void(0)" [routerLink]="[nav]">{{nav}}</a>
			</li>
			<li class="breadcrumb-item active"><i class="fa {{icon}}"></i>{{heading}}</li>
		</ol>
	</div>
</div>
