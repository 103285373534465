import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimeFormat} from './time-pipe.module';

@NgModule({
	imports: [
		CommonModule
	],
	exports: [
		TimeFormat
	],
	declarations: [TimeFormat]
})
export class SharedPipesModule { }

