import { Injectable } from '@angular/core';
import { FormGroup, FormArray, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { CountryISO } from 'ngx-intl-tel-input';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Injectable()
export class FormHelperService {

	constructor() { }

	isFieldValid(form: FormGroup, field: string) {
		return form.get(field).errors
			&& form.get(field).touched
			&& !form.get(field).valid;
	}

	isArrayIndexValid(form: FormArray, index: number) {
		return form.at(index).errors
			&& form.at(index).touched
			&& !form.at(index).valid;
	}

	hasError(form: FormGroup, field: string, validationType: string) {
		return form.get(field).hasError(validationType)
			&& form.get(field).touched
			&& !form.get(field).valid;
	}

	markControlsAsTouched(
		rootControl: FormGroup | FormArray,
		visitChildren: boolean = true) {

		let stack: (FormGroup | FormArray)[] = [];

		// Stack the root FormGroup or FormArray
		if (rootControl &&
			(rootControl instanceof FormGroup ||
				rootControl instanceof FormArray)) {
			stack.push(rootControl);
		}

		while (stack.length > 0) {
			let currentControl = stack.pop();
			(<any>Object).values(currentControl.controls).forEach((control) => {
				// If there are nested forms or formArrays, stack
				// them to visit later
				if ((control instanceof FormGroup ||
					control instanceof FormArray) &&
					visitChildren) {
					stack.push(control);
				} else {
					control.markAsTouched();
				}
			});
		}
	}

	customDateValidator(dateField: string, timeField: string): ValidatorFn {
		return (form: FormGroup) => {
			const date = form.get(dateField).value;
			const time = form.get(timeField).value;

			if (!date || !time) return null;

			return new Date(date + ' ' + time) < new Date() ? { expired: true } : null;
		};
	}

	getCountryCode(value) {
		if (!value) return CountryISO.UnitedStates;

		try {
			let number: PhoneNumber = phoneNumberUtil.parse(value);
			let countryCode = phoneNumberUtil.getRegionCodeForNumber(number);
			return countryCode ?? CountryISO.UnitedStates;
		} catch (e) {
			return CountryISO.UnitedStates;
		}
	}
}
