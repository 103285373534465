
import { of as observableOf, Observable, Subject } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHandler, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import 'rxjs/Rx';
import { environment } from '../environments/environment';

@Injectable()
export class RouteService {
	public status;
	public message: string;
	public basepath: string = environment.apiUrl;

	private subject = new Subject<any>();

	constructor(
		private http: HttpClient,
		private router: Router
	) { }

	get Authorization() {
		return localStorage.getItem('Authorization');
	}

	sendMessage(message: string) {
		this.subject.next({ text: message });
	}

	clearMessage() {
		this.subject.next();
	}

	getMessage(): Observable<any> {
		return this.subject.asObservable();
	}

	handleError(error) {
		console.log('error from backend', error);

		this.status = error.status;
		if (error.status == 401) {
			localStorage.clear();

			if (!(error.url.indexOf('changepassword') > -1))
				this.router.navigate(['/login']);

			var message = error;

			if (message['error']['message'] == undefined) {
				this.message = 'Wrong password!';
			} else {
				this.message = message['error']['message'];
			}
			return observableOf(this.message);
		} else if (error.status == 403) {
			if (error.error.error == 'user_disabled')
				this.router.navigate(['/reset-password']);

			this.message = 'You don\'t have permission';
			return observableOf(this.message);
		} else if (error.status == 400) {
			this.message = error.error.message;
			return observableOf(error.error);
		} else if (error.status == 422) {
			return observableOf(error.error);
		} else {
			this.message = error;
			return observableOf(error.statusText);
		}
	}

	extractData(data) {
		this.status = 200;
		return data;
	}

	getData(path): Observable<object> {
		const Authorization = this.Authorization;
		let headers = null;
		if (Authorization)
			headers = new HttpHeaders({ Authorization });

		return this.http.get(this.basepath + path, { headers }).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	getDataHereMap(path): Observable<object> {
		return this.http.get(path).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	getHereMapData(query, additionalData = ''): Observable<object> {
		const app_id = 'bC4fb9WQfCCZfkxspD4z';
		const app_code = 'K2Cpd_EKDzrZb1tz0zdpeQ';

		let hereMapUrl = 'https://geocoder.api.here.com/6.2/geocode.json';
		hereMapUrl += '?searchtext=' + query;

		if (additionalData !== '') {
			hereMapUrl += additionalData;
			//=IncludeShapeLevel,postalCode
		}
		hereMapUrl += '&app_id=' + app_id;
		hereMapUrl += '&app_code=' + app_code;

		return this.http.get(hereMapUrl).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	getHereMapsPlace(href) {
		return this.http.get(href).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	postData(path, data): Observable<object> {
		const Authorization = this.Authorization;
		let headers = null;
		if (Authorization)
			headers = new HttpHeaders({ Authorization });

		return this.http.post(this.basepath + path, data, { headers }).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	postUpload(path, data): Observable<object> {
		const Authorization = this.Authorization;
		let headers = null;
		if (Authorization) {
			headers = new HttpHeaders({ Authorization });
			headers.append('Content-Type', 'multipart/form-data');
		}

		return this.http.post(this.basepath + path, data, { headers }).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	putData(path, data): Observable<object> {
		const Authorization = this.Authorization;
		let headers = null;
		if (Authorization)
			headers = new HttpHeaders({ Authorization });

		return this.http.put(this.basepath + path, data, { headers }).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	public uploadData(path, data): Observable<object> {
		const Authorization = this.Authorization;
		let headers = null;
		if (Authorization)
			headers = new HttpHeaders({ Authorization });

		return this.http.post(this.basepath + path, data, { headers }).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}

	deleteData(path): Observable<object> {
		const Authorization = this.Authorization;
		let headers = null;
		if (Authorization)
			headers = new HttpHeaders({ Authorization });

		return this.http.delete(this.basepath + path, { headers }).pipe(
			map(data => this.extractData(data)),
			catchError(error => this.handleError(error)));
	}
}
