import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class DatetimeHelperService {

	abbrMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
	monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	constructor() { }

	isDateBeforeToday(date: Date): boolean {
		let timezoneOffset = new Date().getTimezoneOffset();
		date.setMinutes(date.getMinutes() + timezoneOffset);

		let today = new Date();
		today.setHours(0, 0, 0, 0);

		return date < today;
	}

	isNextDate(form: FormGroup, fieldFrom: string, fieldTo: string): boolean {
		if (!form.get(fieldFrom) || !form.get(fieldTo)) return false;

		const timeFromValue = form.get(fieldFrom).value;
		const timeToValue = form.get(fieldTo).value;

		if (!timeFromValue || !timeToValue) return false;

		const timeFromArr = form.get(fieldFrom).value.split(':', 2);
		const timeToArr = form.get(fieldTo).value.split(':', 2);

		let timeFrom = new Date();
		timeFrom.setHours(timeFromArr[0]);
		timeFrom.setMinutes(timeFromArr[1]);

		let timeTo = new Date();
		timeTo.setHours(timeToArr[0]);
		timeTo.setMinutes(timeToArr[1]);

		return timeTo < timeFrom;
	}
	getMonday() {
		let d = new Date();
		let day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
		return new Date(d.setDate(diff)).getDate();
	}

	getSunday() {
		let d = new Date();
		let day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6 : 1) + 6; // adjust when day is sunday
		return new Date(d.setDate(diff)).getDate();
	}

	getMonthAbbr() {
		return this.abbrMonthNames[new Date().getMonth()];
	}
	getMonth() {
		return this.monthNames[new Date().getMonth()];
	}
	getYear() {
		return new Date().getFullYear();
	}
	getQuarter() {
		return 'Q' + (Math.floor(new Date().getMonth() / 3) + 1);
	}
}
